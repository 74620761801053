<template lang="pug">
.new-campaign-sab-plan
  Layout(@back="navigateBack")
    .container.d-flex
      .icon
        om-monks(monk="medit-wizard")
      .left-section
        om-heading(h3) {{ $t('optimization.sab.branching.left-section.heading') }}
        .color-gray-ultra-dark {{ $t('optimization.sab.branching.left-section.subHeading') }}
        om-button.recommendation(
          :disabled="noRecommendation"
          primary
          @click="generateRecommendation"
        ) {{ $t('optimization.sab.branching.left-section.ctaText') }}
      .separator
        wizard-separator {{ $t('or') }}
      .right-section
        om-heading(h3) {{ $t('optimization.sab.branching.right-section.heading') }}
        .color-gray-ultra-dark {{ $t('optimization.sab.branching.right-section.subHeading') }}
        om-link.mb-2(primary withIconRight @click="handleManualSetup")
          a.skip-wizard {{ $t('optimization.sab.branching.right-section.ctaText') }}
  portal(to="root")
    om-toast.sab-plan-notification(v-if="noRecommendation" color="danger" type="full")
      span {{ $t('optimization.notifications.generationError') }}
</template>

<script>
  import { mapGetters } from 'vuex';
  import { lsRetrieve, lsRemove } from '@/helpers/localStorage';
  import urlModsMixin from '@/mixins/urlMods';
  import campaignCreateMixin from '@/mixins/newCampaignFlow/campaignCreate';

  export default {
    components: {
      Layout: () => import('@/components/NewCampaignFlow/Layout.vue'),
      WizardSeparator: () => import('@/components/Wizard/Separator.vue'),
    },
    mixins: [urlModsMixin, campaignCreateMixin],
    data: () => ({ siteInfo: null, noRecommendation: null, isLoading: true }),
    computed: {
      ...mapGetters(['domains', 'isSuperAdmin', 'databaseId']),
    },
    mounted() {
      this.setCampaignDomain(this.$route.query.url);
      const lsErrorKey = `error-${this.$route.query.url}`;
      this.noRecommendation = !!lsRetrieve(lsErrorKey);
      lsRemove(lsErrorKey);
      const lsSiteInfoKey = `siteinfo-${this.url.domain}`;
      this.siteInfo = lsRetrieve(lsSiteInfoKey);
    },
    methods: {
      navigateBack() {
        this.$router.go(-1);
      },
      generateRecommendation() {
        this.$router.push({
          name: 'new_campaign_flow_sab_recommendations',
          query: { url: this.$route.query.url },
          params: { siteInfoId: this.siteInfo?.om },
        });
      },
      async handleManualSetup() {
        this.createOrInsertCodeRedirect({
          userId: this.siteInfo?.om,
          domain: this.savedDomain,
          hasRequestDate: this.hasRequestRecently(this.savedDomain),
          redirectPath: this.url.path,
        });
      },
    },
  };
</script>

<style lang="sass">
  @import '@/sass/variables/_colors'
  .sab-plan-notification
    position: absolute
    bottom: 0
    right: 0
    margin: 1rem
    width: 25%
  .new-campaign-sab-plan
    .container
      max-width: 1170px
      margin-top: 152px
      gap: 40px
      .icon
        min-width: 14.6875rem
      .left-section
        .recommendation
          max-width: 14.0625rem
      .left-section, .right-section
        display: flex
        flex-direction: column
        gap: 16px
</style>
